<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :is-page-show="true"
      api-url="/inventory/v1/list"
      @showCreateDialog="showCreateDialog"
      @showDetailDialog="showDetailDialog"
    ></custom-table>

    <v-dialog v-model="createDialog" max-width="640px">
      <InventoryCreate
        v-if="createDialog"
        @cancel="hideCreateDialog"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="detailDialog" max-width="100vw">
      <InventoryDetail
        v-if="detailDialog"
        :item-detail="itemDetail"
        @cancel="hideDetailDialog"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  INVENTORY_STATUS_OPTIONS,
  INVENTORY_TYPE_OPTIONS,
  INVENTORY_CLASSIFY_OPTIONS,
} from "@/libs/const";
export default {
  components: {
    InventoryCreate: () => import("@/components/goods/InventoryCreate"),
    InventoryDetail: () => import("@/components/goods/InventoryDetail"),
  },
  name: "Inventory",
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    createDialog: false,
    detailDialog: false,
    itemDetail: {},
    statusOptions: [...INVENTORY_STATUS_OPTIONS],
    typeOptions: [...INVENTORY_TYPE_OPTIONS],
    classifyOptions: [...INVENTORY_CLASSIFY_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        actionPermissions: [],
        actionModules: [],
        action: "showDetailDialog",
      },
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse"),
        placeholder: this.$t("labels.warehouse"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.classify"),
        placeholder: this.$t("labels.classify"),
        name: "classify",
        hasSort: false,
        sortName: "classify",
        key: "classify",
        options: this.classifyOptions,
        required: true,
      },
      {
        type: "select-customer",
        label: this.$t("labels.customer"),
        placeholder: this.$t("labels.customer"),
        name: "id_customer",
        hasSort: false,
        sortName: "company_name",
        key: "company_name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.type"),
        placeholder: this.$t("labels.type"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: this.typeOptions,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.start_date"),
        placeholder: this.$t("labels.start_date"),
        name: "start_date",
        hasSort: false,
        sortName: "start_date",
        key: "start_date",
        format: "DD/MM/YYYY",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.end_date"),
        placeholder: this.$t("labels.end_date"),
        name: "end_date",
        hasSort: false,
        sortName: "end_date",
        key: "end_date",
        format: "DD/MM/YYYY",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.quantity"),
        placeholder: this.$t("labels.quantity"),
        name: "request_sku",
        hasSort: false,
        sortName: "request_sku",
        key: "request_sku",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.created_at"),
        placeholder: this.$t("labels.created_at"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [
      {
        text: this.$t("labels.add"),
        action: "showCreateDialog",
        link: "",
        modules: [],
        permissions: [],
        color: "success",
        class: "mr-2",
      },
    ];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDetailDialog(item) {
      this.itemDetail = { ...item };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.itemDetail = {};
      this.detailDialog = false;
    },
    showCreateDialog() {
      this.createDialog = true;
    },
    hideCreateDialog() {
      this.createDialog = false;
    },
  },
};
</script>
